import { styled, alpha } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FilterList, TableRows } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}))

const StyledMenuComponent = ({ setOpenModal, anchorEl, open, handleClose, fetchData, isLoadingFetchData }) => {
	return (
		<StyledMenu
			id='demo-customized-menu'
			MenuListProps={{
				'aria-labelledby': 'demo-customized-button',
			}}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
		>
			<MenuItem
				onClick={() => {
					setOpenModal(true)
					handleClose()
				}}
				disableRipple
			>
				<FilterList />
				<span>Tabla actual</span>
			</MenuItem>
			<MenuItem
				disabled={!fetchData || isLoadingFetchData}
				onClick={() => {
					fetchData()
				}}
				disableRipple
			>
				{isLoadingFetchData ? <CircularProgress size={17} sx={{ marginRight: '13px' }} /> : <TableRows />}
				<span>Todas las páginas</span>
			</MenuItem>
		</StyledMenu>
	)
}

export default StyledMenuComponent
