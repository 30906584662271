import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	...modalStyle(theme),
	rootModal: {
		overflow: 'hidden',
		justifyContent: 'flex-end',
		padding: '0px 20px',
	},
	btnModal: {
		marginRight: '10px',
	},
	btnExport: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
})

export default styles
