import React from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { TableVirtuoso } from 'react-virtuoso'
import { withStyles } from '@material-ui/core/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import sortDescIcon from '../assets/icons/sortDescIcon.svg'
import sortAscIcon from '../assets/icons/sortAscIcon.svg'
import {
	defaultDateFormat,
	defaultAlignCell,
	defaultNullDate,
	defaultNullValue,
	actionText,
	types,
	defaultSize,
} from '../helpers/values'
import { currencyFormat, getNumber } from '../helpers/functions'
import styles from '../styles/reactTableVirtuosoStyles'

class ReactTableVirtuoso extends React.PureComponent {
	static defaultProps = {
		data: [],
	}

	VirtuosoTableComponents = {
		Scroller: React.forwardRef((props, ref) => (
			<TableContainer
				className={this.props.classes.tableContainer}
				component={Paper}
				{...props}
				id='table-container'
				ref={ref}
			/>
		)),
		Table: (props) => (
			<Table
				id='table-virtuoso'
				aria-label='sticky table'
				{...props}
				sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
			/>
		),
		TableHead: (props) => <TableHead ref={this.props.tableHeaderRef} id='table-head' {...props} />,
		TableRow: ({ item: _item, ...props }) => (
			<TableRow id={`table-body-row-${props['data-item-index']}`} hover {...props} />
		),
		TableBody: React.forwardRef((props, ref) => (
			<TableBody id='table-body' className={this.props.classes.tableBody} {...props} ref={ref} />
		)),
	}

	fixedHeaderContent(props) {
		const { classes, columns, sortColumn, sortOrder, filterValues, handleSort, handleFilterChange, errorInputDate } =
			props
		return (
			<TableRow id='table-row-header'>
				{columns
					?.filter((e) => !Boolean(e?.hidden))
					.map((column, index) => {
						const {
							header,
							accessor,
							cellAlign,
							headerAlign,
							size,
							type,
							dateFormat,
							disabledSearch,
							width,
							disabledDateInput = false,
							disabledSort,
						} = column
						return (
							<TableCell
								id={`table-cell-header-${index}`}
								key={'header-cell-' + index}
								className={`${classes.rows} ${classes[size || defaultSize]} ${classes.headerText}`}
								variant='head'
								align={headerAlign || defaultAlignCell}
								sx={{
									backgroundColor: 'background.paper',
									width: `${width} !important` || 'inherit',
								}}
							>
								{header}
								{accessor !== actionText && (
									<>
										{!disabledSort && (
											<button className={classes.btnSort} onClick={() => handleSort(accessor)}>
												{sortColumn === accessor && sortOrder === 'asc' ? (
													<img src={sortAscIcon} className={classes.iconSort} />
												) : sortColumn === accessor && sortOrder === 'desc' ? (
													<img src={sortDescIcon} className={classes.iconSort} />
												) : (
													<div>
														<img src={sortAscIcon} className={classes.iconSort} />
														<img src={sortDescIcon} className={classes.iconSort} />
													</div>
												)}
											</button>
										)}
										{!disabledSearch && (
											<input
												className={classes.input}
												placeholder={disabledSearch ? '' : 'Buscar...'}
												type='text'
												style={
													Boolean(errorInputDate[accessor])
														? { textAlign: defaultAlignCell, color: 'red', borderColor: 'red' }
														: { textAlign: defaultAlignCell }
												}
												value={filterValues?.[accessor] || ''}
												onChange={(event) => handleFilterChange({ event, accessor, type, disabledDateInput })}
												disabled={disabledSearch}
											/>
										)}
									</>
								)}
							</TableCell>
						)
					})}
			</TableRow>
		)
	}

	rowContent(row, _index, props) {
		const { columns, classes } = props
		return (
			<>
				{columns
					?.filter((e) => !Boolean(e?.hidden))
					.map((column, index) => {
						const { header, accessor, cellAlign, headerAlign, size, type, dateFormat, cell, nullValue } = column
						const isDate = type === types.date
						const isNumber = type === types.number
						const isCurrency = type === types.currency
						const isNull = !Boolean(row[accessor])
						const isCustomize = Boolean(cell)

						const value = !isNull
							? row[accessor]
							: nullValue
							? nullValue
							: isDate
							? defaultNullDate
							: isNumber || isCurrency
							? 0
							: defaultNullValue
						const cellValue =
							isDate && !isNull
								? format(new Date(value), dateFormat || defaultDateFormat, { locale: es })
								: isCurrency && !isNull
								? currencyFormat.format(getNumber(value))
								: value

						return (
							<TableCell
								key={'cell-' + index}
								id={`table-cell-body-${index}`}
								align={cellAlign || defaultAlignCell}
								className={classes.rows}
							>
								{isCustomize ? cell({ row, cell: cellValue }) : cellValue}
							</TableCell>
						)
					})}
			</>
		)
	}

	onScroll(e) {
		// console.log(e.target.scrollTop) Function to get Table body scrollTop
	}

	render() {
		const { data, referer, handleHeight } = this.props
		return (
			<TableVirtuoso
				id='table-virtuoso'
				onScroll={this.onScroll}
				style={{ maxHeight: '90vh' }}
				ref={referer}
				data={data}
				components={this.VirtuosoTableComponents}
				fixedHeaderContent={() => this.fixedHeaderContent(this.props)}
				itemContent={(_index, row) => this.rowContent(row, _index, this.props)}
				totalListHeightChanged={handleHeight}
				overscan={50}
			/>
		)
	}
}

const ReactTableVirtuosoStyled = withStyles(styles)(ReactTableVirtuoso)

export default ReactTableVirtuosoStyled
