import { KeyboardArrowDown } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import { useEffect, useState } from 'react'
import StyledMenuComponent from './StyledMenu'

const MenuButton = ({ classes, successFetchData, styleBtn, fetchData, setOpenModal, isLoadingFetchData }) => {
	const [anchorEl, setAnchorEl] = useState(false)

	useEffect(() => {
		if (successFetchData) {
			handleClose()
		}
	}, [successFetchData])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<div className={classes.btnExport}>
				<Button endIcon={<KeyboardArrowDown />} style={styleBtn} color='primary' onClick={handleClick}>
					Exportar Excel
				</Button>
			</div>
			<StyledMenuComponent
				isLoadingFetchData={isLoadingFetchData}
				fetchData={fetchData}
				setOpenModal={setOpenModal}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
			/>
		</>
	)
}

export default MenuButton
