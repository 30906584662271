import React, { useState } from 'react'
import ReactExport from 'react-data-export'
import { makeStyles, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Clearfix from 'components/Clearfix/Clearfix'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import MenuButton from './components/MenuButton'
import styles from './styles/excelExportStyles'

const useStyles = makeStyles(styles)

const ExcelExport = ({
	excelName = '',
	data = [],
	columns = [],
	styleBtn,
	fetchData,
	isLoadingFetchData = false,
	successFetchData = false,
	ResetFetchData = () => {},
}) => {
	const classes = useStyles()
	const ExcelFile = ReactExport.ExcelFile

	const [open, setOpen] = useState(false)

	const handleSendExcel = (e) => {
		e.preventDefault()
		setOpen(false)
		ResetFetchData()
	}

	const handleCloseModal = () => {
		setOpen(false)
		ResetFetchData()
	}

	return (
		<>
			<MenuButton
				setOpenModal={setOpen}
				successFetchData={successFetchData}
				classes={classes}
				fetchData={fetchData}
				isLoadingFetchData={isLoadingFetchData}
				styleBtn={styleBtn}
			/>

			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={open || successFetchData}
				keepMounted
				onClose={handleCloseModal}
				aria-labelledby='notice-modal-slide-title'
				aria-describedby='notice-modal-slide-description'
			>
				<form onSubmit={handleSendExcel} autoComplete='false'>
					<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key='close'
							aria-label='Close'
							color='transparent'
							onClick={handleCloseModal}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>{excelName}</h4>
					</DialogTitle>

					<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={12}>
									<p>¿Está seguro que desea exportar {excelName} en formato excel? </p>
									<p>
										Total de filas a exportar: <b>{data.length}</b>
									</p>
								</GridItem>
							</GridContainer>
						</GridItem>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<GridContainer className={classes.rootModal}>
							<Button className={classes.btnModal} onClick={handleCloseModal}>
								Cancelar
							</Button>
							<Clearfix />
							<ExcelFile
								element={
									<Button type='submit' color='primary'>
										Exportar Excel
									</Button>
								}
								filename={excelName}
								hideElement={false}
							>
								<ExcelFile.ExcelSheet data={data} name={excelName}>
									{columns?.length > 0 &&
										columns?.map((column, index) => (
											<ExcelFile.ExcelColumn key={index} label={column.header} value={column.accessor} />
										))}
								</ExcelFile.ExcelSheet>
							</ExcelFile>
							<Clearfix />
						</GridContainer>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default ExcelExport
